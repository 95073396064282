<template>
  <form
    class="general-configuration custom-form h-100 d-flex flex-column animate__animated animate__fadeIn"
    @submit.prevent="save"
    novalidate
  >
    <h2 class="section-subtitle">Información general del negocio</h2>

    <div class="form-container">
      <div class="form-group d-flex flex-column flex-md-row">
        <div class="label-container mb-2 mb-md-0">
          <label for="storeType">Tipo de tienda</label>
        </div>

        <div class="form-field">
          <FormSelect
            id="storeType"
            v-model="form[0].value"
            :items="storeTypes"
            defaultOption="Elegir tipo de tienda"
          />
        </div>
      </div>

      <div class="form-group d-flex flex-column flex-md-row mb-0">
        <div class="label-container mb-2 mb-md-0">
          <label for="storeType">Control de mayoría de edad</label>
        </div>

        <div class="form-field d-flex align-items-center">
          <b-form-checkbox id="dealers" v-model="form[11].value" switch>
          </b-form-checkbox>

          <p class="my-0 d-flex flex-column ml-1">
            <span class="age-verification-title">Bloqueo según mayoría de edad</span>
            <span class="age-verification-description">Depende de los motores de búsqueda atender esta petición o no.</span>
          </p>
        </div>
      </div>
    </div>
    <div class="form-container">
      <div class="form-group d-flex flex-column flex-md-row">
        <div class="label-container">
          <label for="identifier">R.U.C.</label>
        </div>

        <div class="form-field">
          <FormInput
            id="identifier"
            type="text"
            maxlength="11"
            placeholder="Ingresar RUC"
            v-model="form[1].value"
          />
        </div>
      </div>

      <div class="form-group d-flex flex-column flex-md-row">
        <div class="label-container">
          <label for="razonSocial">Razón social</label>
        </div>

        <div class="form-field">
          <FormInput
            id="razonSocial"
            type="text"
            placeholder="Ingresar Razón social"
            v-model="form[2].value"
          />
        </div>
      </div>

      <div class="form-group d-flex flex-column flex-md-row">
        <div class="label-container">
          <label for="name">Nombre Comercial</label>
        </div>

        <div class="form-field">
          <FormInput
            id="name"
            type="text"
            placeholder="Ingresar Nombre Comercial"
            v-model="form[3].value"
          />
        </div>
      </div>

      <div class="form-group d-flex flex-column flex-md-row">
        <div class="label-container">
          <label for="address">Dirección fiscal</label>
        </div>

        <div class="form-field">
          <FormInput
            id="address"
            type="text"
            placeholder="Ingresar dirección fiscal"
            v-model="form[4].value"
          />
        </div>
      </div>

      <div class="form-group d-flex flex-column flex-md-row">
        <div class="label-container">
          <label for="mobile">Celular</label>
        </div>

        <div class="form-field">
          <b-input-group
            prepend="+51"
            class="form-phone-group d-flex flex-nowrap rounded-0"
          >
            <FormInput
              id="mobile"
              type="text"
              maxlength="20"
              placeholder="Ingresar celular"
              v-model="form[5].value"
              @input="$v.form[5].value.$touch()"
            />
          </b-input-group>

          <FormError
            v-if="$v.form[5].value.$error && !$v.form[5].value.regex"
            message="Número de celular solo debe contener números"
          />
        </div>
      </div>

      <div class="form-group d-flex flex-column flex-md-row">
        <div class="label-container">
          <label for="whatsapp">Whatsapp</label>
        </div>

        <div class="form-field">
          <b-input-group
            prepend="+51"
            class="form-phone-group d-flex flex-nowrap rounded-0"
          >
            <FormInput
              id="whatsapp"
              type="text"
              maxlength="20"
              placeholder="Ingresar whatsapp"
              v-model="form[6].value"
              @input="$v.form[6].value.$touch()"
            />
          </b-input-group>

          <FormError
            v-if="$v.form[6].value.$error && !$v.form[6].value.regex"
            message="Número de Whatsapp solo debe contener números"
          />
        </div>
      </div>

      <div
        id="email-container"
        class="form-group d-flex flex-column flex-md-row mb-0"
      >
        <div class="label-container">
          <label for="email">Correo electrónico</label>
        </div>

        <div class="form-field">
          <FormInput
            id="email"
            type="email"
            maxlength="100"
            placeholder="Ingresar correo electrónico"
            v-model="form[7].value"
          />

          <!-- <form-error
          message="Correo electrónico es requerido"
          v-if="$v.form.email.$error && !$v.form.email.required"
        ></form-error>
        <form-error
          message="Ingrese un correo electrónico válido"
          v-if="$v.form.email.$error && !$v.form.email.email"
        ></form-error> -->
        </div>
      </div>
    </div>

    <div class="form-container">
      <div class="form-group description d-flex flex-column flex-md-row">
        <div class="label-container">
          <label for="description">Descripción del negocio</label>
        </div>

        <div class="form-field">
          <global-trumbowyg v-model="form[8].value" />
        </div>
      </div>
    </div>

    <div class="form-container border-bottom-0">
      <div class="form-group d-flex flex-column flex-md-row">
        <div class="label-container">
          <label for="min">Visibilidad en los motores de búsqueda</label>
        </div>

        <div class="form-field d-flex">
          <b-form-checkbox v-model="form[9].value" switch> </b-form-checkbox>

          <div class="ml-2">
            <p class="check-subtitle mb-0">
              Pedir a los motores de búsqueda que no indexen este sitio.
            </p>
            <p class="check-description my-0">
              Depende de los motores de búsqueda atender esta petición o no.
            </p>
          </div>
        </div>
      </div>

      <div class="form-group d-flex flex-column flex-md-row mb-0">
        <div class="label-container">
          <label for="min">Página web en mantenimiento</label>
        </div>

        <div class="form-field d-flex">
          <b-form-checkbox v-model="form[10].value" switch> </b-form-checkbox>

          <div class="ml-2">
            <p class="check-subtitle mb-0">
              Activar página web en mantenimiento
            </p>
            <p class="check-description my-0">
              Todas las visitas se direccionarán a la página principal (portada)
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="form-group-buttons text-right mt-auto mb-0 pt-0">
      <button class="button button-primary" type="submit">Guardar</button>
    </div>
  </form>
</template>

<script>
import { helpers } from "vuelidate/lib/validators";

import { FormError, FormInput, FormSelect } from "wize-admin";

import { Constants as LocalConstants } from "@/core/utils";
import GlobalTrumbowyg from "@/core/components/common/GlobalTrumbowyg";

import ConfigMixin from "@/core/mixins/ConfigMixin";

export default {
  mixins: [ConfigMixin],
  data() {
    return {
      storeTypes: [
        LocalConstants.StoreTypes.BOUTIQUE,
        LocalConstants.StoreTypes.MINIMARKET,
      ],
    };
  },
  async mounted() {
    await this.getStoreConfig();

    if (this.$route.query.email) {
      let email = document.getElementById("email");
      email.focus();
    }
  },
  components: {
    FormInput,
    FormError,
    FormSelect,
    GlobalTrumbowyg,
  },
  validations: {
    form: {
      5: {
        value: {
          regex: helpers.regex("alpha", /^[0-9]*$/),
        },
      },
      6: {
        value: {
          regex: helpers.regex("alpha", /^[0-9]*$/),
        },
      },
    },
  },
};
</script>

<style lang="stylus">
@import '../Styles/general-configuration.styl';
</style>
